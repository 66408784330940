var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice, nanoid } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createStatClientResponse, deleteManyStatClientResponse, deleteStatClientResponse, editStatClientResponse, getAllStatClientResponses, getOneStatClientResponse, statsClientResponseFilter, } from './actions';
var initialState = {
    statClientResponses: { docs: [], meta: {} },
    statClientResponse: {},
    status: IStatus.IDLE,
    isFiltered: false,
    filters: [],
};
var slice = createSlice({
    name: 'statClientResponses',
    initialState: initialState,
    reducers: {
        addFilter: function (state) {
            state.filters = __spreadArray(__spreadArray([], state.filters, true), [{ id: nanoid(), type: '', value: '' }], false);
        },
        removeFilter: function (state, _a) {
            var payload = _a.payload;
            state.filters = state.filters.filter(function (filter) { return filter.id !== payload.id; });
        },
        handleChangeOptionfilter: function (state, _a) {
            var payload = _a.payload;
            var name = payload.name, value = payload.value;
            state.filters = state.filters.map(function (elt) { return (elt.id === name ? __assign(__assign({}, elt), { type: value }) : elt); });
        },
        handleChangefilter: function (state, _a) {
            var payload = _a.payload;
            var id = payload.id, value = payload.value;
            state.filters = state.filters.map(function (elt) { return (elt.id !== id ? elt : __assign(__assign({}, elt), { value: value })); });
        },
        resetFilters: function (state) {
            state.filters = [];
        },
        setIsFiltered: function (state, action) {
            state.isFiltered = action.payload;
        },
        handleChoiceFilters: function (state, _a) {
            var payload = _a.payload;
            var id = payload.id, choices = payload.choices;
            var index = state.filters.findIndex(function (filter) { return filter.id === id; });
            var updatedFilters = __spreadArray([], state.filters, true);
            if (index !== -1) {
                updatedFilters[index] = __assign(__assign({}, updatedFilters[index]), { choices: choices });
            }
            return __assign(__assign({}, state), { filters: updatedFilters });
        },
    },
    extraReducers: function (builder) {
        // create
        builder
            .addCase(createStatClientResponse.pending, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(createStatClientResponse.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.statClientResponse = action.payload.data;
        })
            .addCase(createStatClientResponse.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // get all
        builder
            .addCase(getAllStatClientResponses.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllStatClientResponses.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.statClientResponses = action.payload.data;
        })
            .addCase(getAllStatClientResponses.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // get one
        builder
            .addCase(getOneStatClientResponse.pending, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getOneStatClientResponse.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.statClientResponse = action.payload.data;
        })
            .addCase(getOneStatClientResponse.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // edit
        builder
            .addCase(editStatClientResponse.pending, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(editStatClientResponse.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.statClientResponse = action.payload.data;
        })
            .addCase(editStatClientResponse.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // delete one
        builder
            .addCase(deleteStatClientResponse.pending, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteStatClientResponse.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.statClientResponses.docs = state.statClientResponses.docs.filter(function (statClientResponse) { return statClientResponse._id !== action.meta.arg.statClientResponseId; });
        })
            .addCase(deleteStatClientResponse.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // delete many
        builder
            .addCase(deleteManyStatClientResponse.pending, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteManyStatClientResponse.fulfilled, function (state) {
            state.status = IStatus.SUCCEEDED;
        })
            .addCase(deleteManyStatClientResponse.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        builder
            .addCase(statsClientResponseFilter.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(statsClientResponseFilter.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.statClientResponses = payload;
        })
            .addCase(statsClientResponseFilter.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var addFilter = (_a = slice.actions, _a.addFilter), removeFilter = _a.removeFilter, handleChangeOptionfilter = _a.handleChangeOptionfilter, handleChangefilter = _a.handleChangefilter, resetFilters = _a.resetFilters, handleChoiceFilters = _a.handleChoiceFilters, setIsFiltered = _a.setIsFiltered;
export default slice.reducer;
